  import { VCard, VCardText, VCardTitle, VIcon, VContainer, VRow, VCol, VBtn, VDialog, VDataTable, VSimpleTable, VCombobox, VSelect, VPagination, VDivider, VList, VListItem, 
    VListItemTitle, VListItemContent, VListItemSubtitle, VListItemAction, VMenu} from 'vuetify/lib';
  // import { NgForm, handler } from '@/components/gui/NgForm'
  import createComponentPageListFilter from '@/modules/DynamicPageListFilter';
  
  export default function createComponentPageList(configPage, tmpl){
      //console.log(tmpl)
      
      return {
        // name: 'default-list',
        components: { VCard, VCardText, VCardTitle, VIcon, VContainer, VRow, VCol, VBtn, VDialog, 
          VDataTable, VSimpleTable, VCombobox, VSelect, VPagination, VDivider, VList, VListItem, VListItemTitle, 
          VListItemContent, VListItemSubtitle, VListItemAction, VMenu },
        data () {
          return {
            params: null,
            keyForm: 1,
            expanded: [],
            headers: [],
            total: 0,
            items: [ ],
            loading: true,
            loadingMore: false,
            itemsKey: 1,
            showFilters: false,
            dialogFilter: false,
            options: { page: 1, itemsPerPage: 15 },
          }
        },
        props: ['pageConfg', 'defaultParams'],
        computed: {
          pages() {
            return Math.ceil(this.total/this.options.itemsPerPage);
          }
        },
        created () {
          console.log('created.....')
          if(this.pageConfg){
            this.querySelections().then(data => {
                      this.items = data.items
                      this.total = data.total
            });
          }
          // console.log(this.defaultParams)
          
        },
        methods: {
          getComponentFilter() {
            return createComponentPageListFilter({}, this.pageConfg.filters.tmpl);
          },
          changePage(e){
              this.search();
          },
          close(){
            this.showFilters = true;
          },
          filter(params){
            this.params = params;
            this.search();
          },
          loadMore(){
            this.options.page++;
            this.loadingMore = true;
            this.querySelections().then(data => {
                    this.items = [ ...this.items, ...data.items]
                    this.total = data.total;
                    this.loadingMore = false;
                    this.itemsKey++;


              });
          },
          search(){
            this.querySelections().then(data => {
                    this.items = data.items
                    this.total = data.total;
                    this.itemsKey++;


              });
          },
          querySelections () {
            return new Promise((resolve, reject) => {
                let params = this.params;
                if(!params){
                  params = {};
                }
                if(this.defaultParams){
                  params = { ...params, ...this.defaultParams}
                }
                const paginate = { page: this.options.page,  max_rows: this.options.itemsPerPage  };
                let order = null;
                if(this.options && this.options.sortBy && this.options.sortBy.length > 0){
                    let sortType = 'ASC';
                    if(this.options.sortDesc[0]){
                        sortType = 'DESC';
                    }
                     order = [{ field: this.options.sortBy[0], direction: sortType  }];
                }
                this.$http.post( this.pageConfg.url, { data: params, '_paginate': paginate, '_order': order })
                .then(res => {
                     let { total, items } = res
                     total = parseInt(total);
                     //this.loading = false;
                     /*setTimeout(() => {
                      //console.log('loaded')
                      const trs = document.getElementsByClassName("th-basic");
                      console.log(trs)

                     }, 500)*/
                     resolve({ items,  total });
                }).catch(err => {
                  console.log(err);
                  //this.loading = false;
                }).finally(() => (  this.loading = false ));
            });
          },
          
          getActionIcon(name){
            //console.log(this.pageConfg['actions'], '....', name)
            if(this.pageConfg && this.pageConfg['actions_btn'] && this.pageConfg['actions_btn'][name]){
                return this.pageConfg['actions_btn'][name]['icon'];
            }
            return "";
          },
          getActionName(name){
                //console.log(this.pageConfg['actions'], '....', name)
                if(this.pageConfg && this.pageConfg['actions_btn'] && this.pageConfg['actions_btn'][name]){
                    return this.pageConfg['actions_btn'][name]['label'];
                }
                return "";
          },
          getColorAction(name){
            if(this.pageConfg && this.pageConfg['actions_btn'] && this.pageConfg['actions_btn'][name] && this.pageConfg['actions_btn'][name]['color']){
                return this.pageConfg['actions_btn'][name]['color'];
            }
            return "primary";
          },
          executeModuleAction(name){
              //console.log(name)
            if(this.pageConfg && this.pageConfg['actions_btn'] && this.pageConfg['actions_btn'][name]){
                //const act = this.pageConfg['actions'][name];
                //act.action = name;
              this.executeActionList({ action: this.pageConfg['actions_btn'][name], itemAction: null })
            }
              
          },
          executeActionList($event){
            //console.log($event.action)
            // const action = this.pageConfg['actions_btn'][$event.action.name];
            let hook = 'hook_'+$event.action.name;
            if($event.action.hook){
                hook = $event.action.hook;
            }
            window['managerHooks'].executeModuleAction(hook, $event.action, $event.itemAction, this);
            
          },
          executeItemAction(name, item){
            console.log(name, item)
            let action = null;
            if(this.pageConfg && this.pageConfg['actions_btn'] && this.pageConfg['actions_btn'][name]){
              action = this.pageConfg['actions_btn'][name];
            }
            let hook = 'hook_' + action.name;
            //console.log(hook)
            if(action.hook){
                hook = action.hook;
            }
            if(window['managerHooks'].hasHook(hook)){
              window['managerHooks'].executeModuleAction(hook, action, item, this);
            }else{
              this.$router.push(action.path + '/' + item.id);
            }
            
          },
          cancel () {
            //this.form.clean()

          },
          save () {
            //this.$emit('save', this.form.value)
          },
          clear(){
            //this.form.clean()
          },
          getObjectActions (actions, item) {
            // const acts = ['edit'];
            return item._actions;
          },
        },
        template: tmpl
   /*     `
<v-container fluid>
  <v-row>
    <v-col cols="12">
      <div>
        <v-data-table
          :headers="pageConfg.headers"
          :items="items"
          :server-items-length="totalDesserts"
          :loading="loading"
          class="elevation-1"
          item-key="name"
          :show-expand="true"
          :expanded.sync="expanded"
        >
        <template v-slot:item.actions="{ item }">
          <v-icon>mdi-plus</v-icon>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            More info about {{ item.name }}
          </td>
        </template>  
        </v-data-table>
      </div>
    </v-col>
  </v-row>
</v-container>`
  */
      }
  }