import { NgForm, handler } from '@/components/gui/NgForm';
import createComponentPageList from '@/modules/DynamicPageList';

export default {
        // name: 'default-list',
        //components: { VCard, VCardText, VCardActions, VCardTitle, VContainer, VRow, VCol, VBtn, VDatePicker, VIcon, VCombobox, VSelect, VDivider },
    data () {
      return {
        //pageConfg: null,
        //config: null
      }
    },
    //props: ['pageConfg'],
    mounted () {

    },
    methods: {
      loadConfigPage(fullRoute){
        const page = this.$store.getters.getPageConfig(fullRoute);
        if(page){
            this.pageConfg = page;
        }else{
          this.getGuiPage(fullRoute).then((config) => {
              const p = JSON.parse(JSON.stringify(config));
              this.pageConfg = p['list-by-company'];
              for(const a in p){
                  if(p[a] ){
                      this.$store.commit('addPage', p[a] );
                  }
              }
            });
        }       
      },
      getGuiPage (url) {
        return new Promise((resolve, reject) => {
            this.$http.post('api/v1/gui'+ url, { url }  ).then(data => {
                 resolve( data.config  );
            }).catch(err => {
                
            }).finally(() => (  this.loading = false ));
        });
      },
      getComponentList() {
        return createComponentPageList({}, this.pageConfg.tmpl);
      },
      /*getActionIcon(name){
            //console.log(this.pageConfg['actions'], '....', name)
            if(this.pageConfg && this.pageConfg['actions_btn'] && this.pageConfg['actions_btn'][name]){
                return this.pageConfg['actions_btn'][name]['icon'];
            }
            return "";
      },
      getActionName(name){
            //console.log(this.pageConfg['actions'], '....', name)
            if(this.pageConfg && this.pageConfg['actions_btn'] && this.pageConfg['actions_btn'][name]){
                return this.pageConfg['actions_btn'][name]['label'];
            }
            return "";
      },
      executeModuleAction(name){
        if(this.pageConfg && this.pageConfg['actions_btn'] && this.pageConfg['actions_btn'][name]){

          this.executeActionList({ action: this.pageConfg['actions_btn'][name], itemAction: null })
        }
          
      },
      executeActionList($event){
        console.log($event)
        let hook = 'hook_'+$event.action.name;
        if($event.action.hook){
            hook = $event.action.hook;
        }
        window['managerHooks'].executeModuleAction(hook, $event.action, $event.itemAction, this);
        
      },  */        
        },
        template: ''

      }
