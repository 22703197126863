<template>
<div>
   <div v-if="pageConfg">
        <component :is="getComponentList()" :pageConfg="pageConfg" :key="keyPage" :defaultParams="params"></component>
      </div>

      <v-dialog v-model="dialogEdit" persistent max-width="680">
        <div v-if="itemEdit">
          <FormSubsidiaryGeneric :itemId="itemEdit.id" ></FormSubsidiaryGeneric>
        </div>
      </v-dialog>
</div>
</template>
<script>

  import FormSubsidiaryGeneric from '@/modules/base/components/FormSubsidiaryGeneric';
  import TemplateBaseList from '@/modules/TemplateBaseList';
  
  export default {
    name: 'CompanySubsidiaries',
    mixins: [TemplateBaseList],
    components: { FormSubsidiaryGeneric },
    data: () => ({
      pageConfg: null,
      keyPage: 1,
      dialogEdit: false,
      itemEdit: null,
      params: { bus_company_id: null},
      fullRoute: '/config/subsidiary/list-by-company'
    }),
    props: ['companyId'],
    mounted(){
        this.loadConfigPage(this.fullRoute);
        this.params['bus_company_id'] = this.companyId;
    },
    methods: {

    }
   
  }
</script>
