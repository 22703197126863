<template>
  <div>
    <v-card style="background-color: white" v-if="pageConfg">
      <component :is="getComponentForm()" :pageConfg="pageConfg" :key="keyPage" :itemId="itemId"></component>
    </v-card>
  </div>
</template>
<script>

  window['managerHooks'].register('hook_close_modal_subsidiary', function (hook, action, item, component) {
      component.$parent.$parent.$parent.$parent.$parent.itemEdit = null;
      component.$parent.$parent.$parent.$parent.$parent.dialogEdit = false;
  });

  import createComponentPageForm from '@/modules/DynamicPageForm';


  export default {
    name: 'FormSubsidiaryGeneric',
    data: () => ({
      fullRoute: '/config/subsidiary/edit-modal',
      pageConfg: null,
      keyPage: 1 
    }),
    props: ['itemId'],

    mounted(){
        this.loadConfigPage(this.fullRoute);
    },
    beforeUpdate(){
    },
    methods: {
     loadConfigPage(fullRoute){
        const page = this.$store.getters.getPageConfig(fullRoute);
        if(page){
            this.pageConfg = page;
        }else{
          this.getGuiPage(fullRoute).then((config) => {
              const p = JSON.parse(JSON.stringify(config));
              //this.pageConfg = p[this.action];
              for(const a in p){
                  if(p[a] ){
                      this.$store.commit('addPage', p[a] );
                  }
              }
              //this.createForm();
            });
        }       
      },
      getGuiPage (url) {
        return new Promise((resolve, reject) => {
            this.$http.post('api/v1/gui'+ url, { url }  ).then(data => {
                 resolve( data.config  );
            }).catch(err => {
                
            }).finally(() => (  this.loading = false ));
        });
      },
      getComponentForm() {
        return createComponentPageForm({}, this.pageConfg.tmpl);
      },
     
    }
   
  }
</script>
