<template>
<div>
    <default-app-bar> </default-app-bar>

 <div class="mx-2">
 <v-row>
  <v-col cols="12" md="6">
             <div class="text-h2"> <v-icon large>mdi-apps-box</v-icon> {{ $t('Empresa') }}: {{title}}</div>
           </v-col>

      <v-col cols="12" class="mt-2">
      <v-tabs center-active  v-model="tabs" show-arrows class="mb-2">
        <v-tab>Información personal</v-tab>
        <v-tab>Sucursales</v-tab>
        <v-tab>Bodegas</v-tab>
        <v-tab>Consecutivos</v-tab>
        <v-tab>Plantillas</v-tab>
        
      </v-tabs>
      <v-tabs-items v-model="tabs">
      <v-tab-item>
        <v-card  class="mb-12 pa-3">
            <FormPersonGeneric  :person="person" @change="updatePerson" :key="keyPerson"> </FormPersonGeneric>
          </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card  class="mb-12 pa-3">

          <CompanySubsidiaries :companyId="$route.params.id"> </CompanySubsidiaries>
          </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card  class="mb-12 pa-3">
          <CompanyWarehouse :companyId="$route.params.id"></CompanyWarehouse>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card  class="pa-3">
         </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card  class="mb-12 pa-3">
          </v-card>
      </v-tab-item>
    </v-tabs-items>
    </v-col>
    </v-row>
  </div>
</div>
</template>
<script>
  import  FormPersonGeneric from '@/modules/base/components/FormPersonGeneric';
  import CompanySubsidiaries from './CompanySubsidiaries';
  import CompanyWarehouse from './CompanyWarehouse';

  export default {
    name: 'CompanyConfig',
    // mixins: [AbstractField],
    components: { FormPersonGeneric, CompanySubsidiaries, CompanyWarehouse },
    data: () => ({
      tabs: null,
      person: null,
      keyPerson: 0,
      title: ''
    }),
 
    mounted(){

      this.loadItem();
    },
    methods: {
      updatePerson(evt){
        console.log(evt)
        this.person = evt;
        this.keyPerson++;
      },
      loadItem() {
            if(this.$route.params.id){
              this.getItem(this.$route.params.id).then(data => {
                        this.item = data.item;
                        this.person = this.item['_person'];
                        this.title = data.title;
                        this.keyPerson++;
                        
                        
              });
            }
          },
 
          getItem (id) {
            return new Promise((resolve, reject) => {
            
                this.$http.post('api/v1/admin/company/get-config/'+id, {}, this, true, false)
                .then(data => {
                     let { item, title } = data
                     resolve({ item, title });
                }).catch(err => {
                  reject(err);
                })
            });
          }, 
    }
   
  }
</script>
