  import { VCard, VCardText, VCardTitle, VContainer, VRow, VCol, VBtn, VIcon, VDatePicker, VCombobox, VSelect, VDivider } from 'vuetify/lib'
  // import { NgForm, handler } from '@/components/gui/NgForm'
  import { NgForm, handler } from '@/components/gui/NgForm'
  
  export default function createComponentPageListFilter(configPage, tmpl){
      // console.log(tmpl)
      return {
        // name: 'default-list',
        components: { VCard, VCardText, VCardTitle, VContainer, VRow, VCol, VBtn, VDatePicker, VIcon, VCombobox, VSelect, VDivider },
        data () {
          return {
            form: null,
            keyForm: 1,
            showFilters: false,
          }
        },
        props: ['pageConfg'],
        mounted () {
         const form = new NgForm(this.pageConfg.filters.form, this);
         this.form = new Proxy(form, handler);
         this.form.init();
 
        },
        methods: {
          close(){
            this.showFilters = true;
          },
          clean() {
              this.form.clean();
          },
          search() {
            // console.log('...............')
            this.showFilters = false;
              this.$emit('search', this.form.value);
          }
        },
        template: tmpl

      }
  }