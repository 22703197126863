<template>
<div>

 <v-row no-gutters>
      <v-col cols="12" class="mt-2">
      
       <ng-form :form="form">
          <v-row no-gutters>
            <!-- @change="changeTypeIdentification" -->
            <ng-select :form="form" :options="config.commons_type_identification_id"  ></ng-select>

            <ng-integer :form="form" :options="config.identification" ></ng-integer>

            <ng-text :form="form" :options="config.name"></ng-text>

            <ng-text :form="form" :options="config.last_name"></ng-text>
     
            <ng-email :form="form" :options="config.email"></ng-email>

            <ng-phone :form="form" :options="config.phone"></ng-phone>
    
            <ng-text :form="form" :options="config.address"></ng-text>
        
        <v-col cols="12" class="mt-4">
          <v-divider></v-divider>
         <div class="text-h3 my-6">Información de Usuario</div>
        </v-col>
        <v-col cols="12" sm="6" :key="form.key">
          <b>Usuario:</b> {{form.email}}
        </v-col>
        <v-col cols="12" sm="6" class="mt-2">
          <ng-text :form="form" :options="config.passwd"></ng-text>
        </v-col>
        <v-col cols="12" class="my-4">
        
          <v-card-actions class="d-flex justify-center" >
            <v-btn outlined color="primary" @click="save" :disabled="!form._valid" :loading="loading">
              {{$t('Guardar')}}
            </v-btn>
          </v-card-actions>
        </v-col>
        </v-row>
        </ng-form>

    </v-col>
    </v-row>
</div>
</template>
<script>
  import { NgForm, handler } from '@/components/gui/NgForm';
  import inputHooks from '@/plugins/InputHooks';
  // hooks registrados en InputHooks
  /*inputHooks.register('hook_requires_last_name', (hook, cmp, options, form)=>{
    if(form && form.commons_type_identification_id && form.commons_type_identification_id.requires_last_name){
      return true;
    }
    return false;
  });*/

  /*inputHooks.register('hook_change_type_identification', (hook, cmp, options, form)=>{
        const inp = form.getInput('last_name');
        if(form && form.commons_type_identification_id && !form.commons_type_identification_id.requires_last_name){
         
          inp.config.required = false;
          inp.config.validate = [];
          inp.updateRules();
          inp.keyInput++;
          setTimeout(()=> {
            inp.testRules();
          }, 100);
    
        }else{
          if(form.config.last_name.required){
            inp.config.required = true;
            inp.config.validate = ['required'];
            inp.updateRules();
            inp.keyInput++;
            setTimeout(()=> {
              inp.testRules();
            }, 100);
          }
        }
  });*/
  //import createComponentPageForm from '@/modules/DynamicPageForm'

  export default {
    name: 'FormPersonGeneric',
    // mixins: [AbstractField],
    data: () => ({
      form: null, //{ date_from: null, date_to: null },
      config: {
          commons_type_identification_id: { name: 'commons_type_identification_id', label: 'Tipo Identificación', size: 6, rounded: false, change: 'hook_change_type_identification', validate: [ 'required' ], required: true, url: 'api/v1/commons/type-identification/search' },
          name: { name: 'name', label: 'Nombre', size: 6, rounded: false, validate: [ 'required' ], required: true },
          last_name: { name: 'last_name', show: 'hook_requires_last_name', label: 'Apellidos', size: 6, rounded: false, validate: [ 'required' ], required: true},
          identification: { name: 'identification', size: 6, label: 'Número Identificación', rounded: false, validate: [ 'required' ], required: true},
          email: { name: 'email', label: 'Email', size: 6, rounded: false, validate: [ 'required' ], required: true},
          phone: { name: 'phone', label: 'Celular', size: 6, rounded: false, validate: [ 'required' ], required: true},
          passwd: { name: 'passwd', label: 'Contraseña', size: 6, rounded: false, validate: []},
          address: { name: 'address', label: 'Dirección', size: 6, rounded: false, validate: [ 'required' ], required: true}
      },
      loading: false    
    }),
    props: ['person'],
    created(){
      const form = new NgForm(this.config, this);
      this.form = new Proxy(form, handler);
    },
    mounted(){
      if(this.person){
        this.loadForm(this.person);
      }
    },
    beforeUpdate(){
    },
    methods: {

      loadForm (item) {
        this.form.setValue({
                        ...item
                      });
        this.form.checkInputs();
      },
      save(){
       this.loading = true;
       const value = this.form.value;
       value.bus_company_id = this.$route.params.id;
       if(this.person && this.person.id){
        value.id = this.person.id;
       }
       this.$http.post( 'api/v1/admin/person/save-owner-company', { data: value }, this, true, false )
      .then(data => {
        this.loading = false;
          if(data.success){
            //this.showMessage({ text: 'El registro guardo correctamente', color: 'success'});
            this.$emit('change', data.item);
            /*if(data.item){
              component.loadForm(res.data.item);
            }*/
          }/*else{
            if(res.data.msg){
              component.showError(res.data.msg);
            }else{
              component.showError('El registro guardo correctamente');
            }
          }*/
          
      }).catch(err => {
        this.loading = false;
        console.log(err);
      });
      },
      changeTypeIdentification(evt){
        //console.log(evt, 'kokokokokokoko')
        if(!evt.requires_last_name){
          this.config.last_name.required = false;
          this.config.last_name.validate = [];
        }else{
          this.config.last_name.required = true;
          this.config.last_name.validate = ['required'];
        }
        this.form.checkInputs();
      }
    }
   
  }
</script>
